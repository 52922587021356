
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import { Fragment } from "vue-fragment";
import vSelect from "vue-select";
import { Therapist, Payment } from "@/interfaces";
import { get, post, patch, put } from "@/api";
import { Route } from "vue-router";
import "@/scss/vue-select.css";
import { TranslateResult } from "vue-i18n";
import i18n from "@/i18n";
import { BillingData } from "@/interfaces/payment";
const pageKey = "billing";

@Component({
  components: {
    vSelect,
    Fragment
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class InfoBillingComponent extends Vue {
  saved = false;
  isDataFilledCorrect = true;
  preDataFilledCorrect = true;
  finished = false;
  skip_company = false;
  billingData: BillingData = {
    email: "",
    name: "",
    tax_id: "",
    street: "",
    building: "",
    apartment: "",
    postal_code: "",
    city: "",
    country: "",
    legal_form: "",
    business_sector: "",
    is_representative: true,
    representative_email: "",
    representative_name: "",
    representative_surname: "",
    active_vat_payer: true
  };
  oldBillingData: BillingData = {
    email: "",
    name: "",
    tax_id: "",
    street: "",
    building: "",
    apartment: "",
    postal_code: "",
    city: "",
    country: "",
    legal_form: "",
    business_sector: "",
    is_representative: true,
    representative_email: "",
    representative_name: "",
    representative_surname: "",
    active_vat_payer: true
  };
  business_sectors: Payment.Sector[] = [];
  legal_forms: Payment.LegalForm[] = [];
  countries: Record<string, string | TranslateResult>[] = [];

  @Prop({
    default: function() {
      return null;
    },
  })
  therapist!: Therapist.TherapistInfo | null;

  mounted() {
    this.getPreSavedCompanyDetails();
    this.getCountries();
    this.getFormsAndSectors();
  }

  async getPreSavedCompanyDetails() {
    try{
      this.skip_company = this.therapist?.skip_company || false;
      if (!this.therapist?.skip_company){
        const { data: billingData } = await get.preSavedCompanyDetails();
        this.billingData = JSON.parse(JSON.stringify(billingData));
        this.oldBillingData = JSON.parse(JSON.stringify(billingData));
        if (!billingData.country && this.therapist?.country){
          this.billingData.country = this.therapist?.country;
          this.oldBillingData.country = this.therapist?.country;
        }
      } 
    } catch (err) {
      console.log(err);
    }
  }

  async getFormsAndSectors() {
    try {
      const { data: formsAndSectors } = await get.formsAndSectors(
        this.$i18n.locale
      );
      if (formsAndSectors) {
        if (this.billingData.country === "PL") {
          this.legal_forms = [...formsAndSectors.legal_forms.poland];
        } else if (this.therapist?.country === "PL") {
          this.legal_forms = [...formsAndSectors.legal_forms.poland];
        } else {
          this.legal_forms = [...formsAndSectors.legal_forms.abroad];
        }
        this.business_sectors = [...formsAndSectors.business_sectors];
        this.legal_forms.forEach((form) => {
          form.name = this.$t(
            "information-filling.law-forms." + form.name.replaceAll(".", "")
          ).toString();
        });
        this.business_sectors.forEach((sector) => {
          sector.name = this.$t(
            "information-filling.sectors." + sector.name.replaceAll(".", "")
          ).toString();
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getCountries() {
    try {
      const { data: dictionaries } = await get.dict();
      if (dictionaries)
        [...dictionaries["countries"]].forEach((lang) =>
          this.countries.push({
            name: this.$t("countries." + lang),
            code: lang,
          })
        );
    } catch (err) {
      console.log(err);
    }
  }

  setIsChecked(element: boolean) {
    if (element) {
      return require(`@/assets/checked.png`);
    } else {
      return require(`@/assets/unchecked.png`);
    }
  }

  getNames(selectOptions: any) {
    return selectOptions.map(
      (option: any) =>
        option.name.charAt(0).toUpperCase() + option.name.slice(1)
    );
  }

  get isSomethingChanged() {
    const keys = Object.keys(this.billingData);
    return keys.filter(key => this.billingData[key]!==this.oldBillingData[key]).length!==0 || this.skip_company !== this.therapist?.skip_company;
  }

  get isDataFilled() {
    const keys = Object.keys(this.billingData);
    let emptyFields = [];
    if (this.skip_company) {
      return true;
    }
    if (!this.billingData.is_representative) {
      emptyFields = [
        ...keys.filter((key: string) => {
          if (!this.billingData[key] && key!=="is_representative" && key!="active_vat_payer" && key !== "apartment")
            return true;
          if (key === "tax_id")
            return this.billingData[key].toString().trim().length !== 10;
          if (key === "email" || key === "representative_email")
            return !this.validateEmail(this.billingData[key]);
          if (
            this.billingData[key].toString().trim() === "" && key !== "apartment"
          ) return true;
        }),
      ];
    } else {
      emptyFields = [
        ...keys.filter((key: string) => {
          if (
            key === "apartment" ||
            key === "representative_name" ||
            key === "representative_surname" ||
            key === "representative_email")
            return false;
          if (!this.billingData[key] && key!=="is_representative" && key!="active_vat_payer")
            return true;
          if (key === "tax_id")
            return this.billingData[key].toString().trim().length !== 10;
          if (key === "email")
            return !this.validateEmail(this.billingData[key]);
          return(
            this.billingData[key].toString().trim() === "" && key !== "apartment"
          );
        }),
      ];
    }
    return emptyFields.length === 0;
  }

  validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async finish() {
    if (this.isDataFilled) {
      this.saved = true;
      await this.submit();
    } else {
      this.isDataFilledCorrect = false;
    }
  }

  get isPreDataFilled() {
    return (this.validateEmail(this.billingData.email)||this.billingData.email.toString().trim()==="") &&
           (this.validateEmail(this.billingData.representative_email)||this.billingData.representative_email.toString().trim()==="") &&
           (this.billingData.tax_id.toString().trim().length === 10 || this.billingData.tax_id.toString().trim()==="")
  }

  async save() {
    try {
      if (this.isPreDataFilled && this.therapist) {
        if (this.skip_company !== this.therapist.skip_company) {
          const { data: therapist } = await patch.therapistInfo({
            skip_company: this.skip_company,
          });
          this.$emit("update:therapist", therapist);
        }
          await put.preSavedCompanyDetails(this.billingData);
          this.oldBillingData = JSON.parse(JSON.stringify(this.billingData));
      } else {
        this.preDataFilledCorrect = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async submit() {
    try {
      if (this.therapist) {
        this.save();
        const billingData = JSON.parse(JSON.stringify(this.billingData));
        if (!this.skip_company) {
          if (this.billingData.is_representative) {
            delete billingData.representative_name;
            delete billingData.representative_surname;
            delete billingData.representative_email;
          } else {
            if (
              this.billingData.representative_surname?.toString().trim() === ""
            )
              delete billingData.representative_surname;
            if (this.billingData.representative_name?.toString().trim() === "")
              delete billingData.representative_name;
          }
          if (
            this.billingData &&
            this.billingData.apartment?.toString().trim() === ""
          )
            delete billingData.apartment;
          await post.billingInfo(billingData);
        }
        if (this.skip_company !== this.therapist.skip_company) {
          const { data: therapist } = await patch.therapistInfo({
            skip_company: this.skip_company,
          });
          console.log(therapist);
          await put.preSavedCompanyDetails(this.billingData);
          this.oldBillingData = JSON.parse(JSON.stringify(this.billingData));
          this.$store.commit("setStage", therapist.completed_fillings);
          this.$emit("update:therapist", therapist);
        }
      }
      if (this.saved) this.finished = true;
    } catch (err) {
      console.log(err);
    }
  }

  @Watch('billingData.country')
  async updateFormsAndSectors(){
    await this.getFormsAndSectors();
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    console.log((!this.isSomethingChanged && to.path === "/dashboard/myprofile/availability"));
    if ( (!this.isSomethingChanged && to.path === "/dashboard/myprofile/availability") || this.finished || this.$store.state.stage ===5 ) {
      next();
    } else {
      this.$openModal({
        component: "InfoFillingModal",
        props: {
          next: () => next(),
          save: () => this.save(),
          cancel: () => {
            return;
          },
          isDataFilled: this.isDataFilled,
          isPreDataFilled: this.isPreDataFilled
        },
        this: this,
      });
    }
  }
}
