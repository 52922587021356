
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import InfoTpayComponent from "./InfoTpayComponent.vue";
import { Route } from "vue-router";
import InfoBillingComponent from "./InfoBillingComponent.vue";
import { Fragment } from "vue-fragment";
import { Therapist } from "@/interfaces";

Component.registerHooks(["beforeRouteLeave"]);

@Component({
  components: {
    InfoBillingComponent,
    InfoTpayComponent,
    Fragment,
  }
})
export default class InfoBillingDecidePage extends Vue {
  @Prop()
  firstTime!: boolean;

  @Prop({
    default: function() {
      return null;
    },
  })
  therapist!: Therapist.TherapistInfo | null;

  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.firstTime)
      this.$refs.infoBillingComponent.beforeRouteLeave(to, from, next);
    else next();
  } 
}
export default interface InfoBillingDecidePageInterface {
  $refs: {
    infoBillingComponent: any;
  };
}
